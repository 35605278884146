import { useMutation } from "@apollo/client";
import { useState } from "react";
import { ADD_DEMO } from "../../services/index";
import { v4 as uuidv4 } from 'uuid';
// import { Link } from "react-router-dom";
// import { v4 as uuidv4 } from 'uuid';
// console.log(uuidv4())
// import { Header } from "../partials";
import './Demo.css';

const Demo = () => {
  const [addDemo, { data, loading, error }] = useMutation(ADD_DEMO);
  const [validations, setValidations] = useState<string[]>([]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [platform, setPlatform] = useState('');

  const submitHandler = () => {
    if(!firstName || !lastName || !email || !phoneNumber || !platform) {
      setValidations(['Error submitting form.', 'Check input forms'])
      return;
    };
    // console.log(firstName, lastName, email, phoneNumber, platform);

    addDemo({ variables: {
      input: {
        demoId: uuidv4(),
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone_number: phoneNumber,
        platform: platform,
        created_at: `${Date.now()}`
      }
    }});
    setFirstName('');
    setLastName('');
    setEmail('');
    setPhoneNumber('');
    setPlatform('');
    console.log('Data: ', data);
  }

  if (loading) return <p>Loading...</p>;
  if (error) return <p>Error :(</p>;

  return (
    <div className="demo">
      {/* <section className="demo--header">
        <Header />
      </section> */}
      <section className="demo--main wrapper--container">
        <div className="demo--title">
          <h1 className="demo--heading">Request a Demo</h1>
        </div>
        <div className="demo--content">
          <div className="demo--form">
            <div className="form">
              {!!validations.length &&
                <div className="form--validations">
                  <ul>{validations.map( (validation, i ) => {
                    return <li key={i}>{ validation }</li>
                  })}
                  </ul>
                </div>
              }
              <form action="">
                <div className="form--container">
                  <div className="form--item">
                    <label htmlFor="firstName" className="form--label form--required">First Name:</label>
                    <input 
                      type="text" 
                      className="form--input" 
                      name="firstName" 
                      id="firstName" 
                      value={firstName}
                      onChange={(event) => {
                        setFirstName(event.target.value);
                      }} 
                      placeholder="Enter first name" 
                      aria-required="true" 
                      aria-invalid="false" 
                      required 
                    />
                  </div>
                  <div className="form--item">
                    <label htmlFor="lastName" className="form--label form--required">Last Name: </label>
                    <input 
                      type="text" 
                      className="form--input" 
                      name="lastName" 
                      id="lastName"
                      value={lastName}
                      onChange={(event) => {
                        setLastName(event.target.value);
                      }}  
                      placeholder="Enter last name" 
                      aria-required="true" 
                      aria-invalid="false" 
                      required 
                    />
                  </div>
                  <div className="form--item">
                    <label htmlFor="email" className="form--label form--required">Business Email: </label>
                    <input 
                      type="email" 
                      className="form--input" 
                      name="email" 
                      id="email"
                      value={email}
                      onChange={(event) => {
                        setEmail(event.target.value);
                      }}
                      placeholder="Enter business email" 
                      aria-required="true" 
                      aria-invalid="false" 
                      required 
                    />
                  </div>
                  <div className="form--item">
                    <label htmlFor="phoneNumber" className="form--label form--required">Phone number:</label>
                    <input 
                      type="tel" 
                      className="form--input" 
                      name="phoneNumber" 
                      id="phoneNumber" 
                      value={phoneNumber}
                      onChange={(event) => {
                        setPhoneNumber(event.target.value);
                      }}
                      placeholder="Enter phone number" 
                      aria-required="true" 
                      aria-invalid="false" 
                      required 
                    />
                  </div>
                  <div className="form--item form--text">
                    <label htmlFor="platform" className="form--label form--required">Select Platform: </label>
                    <select value={platform} onChange={(event) => { setPlatform(event.target.value);} } className="form--input" style={{height: '3rem'}} name="platform" id="platform" aria-required required>
                      <option value="" disabled>Select product</option>
                      <option value='algoforms'>Algoforms</option>
                      <option value='algoboard'>Algoboard</option>
                    </select>
                  </div>
                </div>
                <div>
                  <button 
                    type="button" 
                    className="form--button"
                    onClick={submitHandler}
                  >Submit <span className="button--arrow">&#10142;</span></button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Demo;