import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Home, About, Platform, Contact, Career, Demo, Blog, Cloud, Privacy, Blockchain, Saas, Error } from "./components/index";
// import { useLocation } from 'react-router-dom';
// import { Header } from "../partials";
// import { Header } from "../src/components/partials";
const App = () => {
  
  return (
    <div className="text-center">
      <Router>
        {/* <Header /> */}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/platform" element={<Platform />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/blogs" element={<Blog />} />
          <Route path="/careers" element={<Career />} />
          <Route path="/demo" element={<Demo />} />
          {/* <Route path="/cloud" element={<Cloud />} /> */}
          <Route path="/cloud" element={<Cloud />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/saas" element={<Saas />} />
          <Route path="/blockchain" element={<Blockchain />} />
          <Route path="*" element={<Error />} />
        </Routes>
      </Router>
    </div>
  );
};

export default App;

// function App() {
//   return (
//     <div className="App">
//       <header className="App-header">
//         <img src={logo} className="App-logo" alt="logo" />
//         <p>
//           Edit <code>src/App.tsx</code> and save to reload.
//         </p>
//         <a
//           className="App-link"
//           href="https://reactjs.org"
//           target="_blank"
//           rel="noopener noreferrer"
//         >
//           Learn React
//         </a>
//       </header>
//     </div>
//   );
// }

// export default App;
