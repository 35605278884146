import { gql } from '@apollo/client';

const ADD_CONTACT = gql`
  mutation addContact($input: addContactInput!) {
    addContact(input: $input) {
      id,
      contactId,
      first_name,
      last_name,
      email,
      phone_number,
      organization,
      country,
      created_at,
      message,
      contactId,
    }
  }
`;

export {
  ADD_CONTACT
}