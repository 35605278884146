import { gql } from '@apollo/client';

const GET_BLOGS = gql`
  query blogs {
    blogs {
      id,
      title,
      content,
      image_url,
      image_source,
      created_at,
      updated_at,
      published_at,
      isPublished,
      blogId,
      userId,
      tags,
    }
  }
`;

export {
  GET_BLOGS
}