// import { Header } from "../partials";
import { Link } from "react-router-dom";
import './Platform.css';

const Platform = () => {

  return (
    <div className="platforms--container">
      {/* <section className='platformss--header'>
        <Header />
      </section> */}
      <section className='platforms--main wrapper--container'>
        {/* <div className="">Platform Page</div> */}
        {/* <div className=''> */}
          <div className='platforms--title'>
            <h2 className=''>Deployed Platforms</h2>
          </div>
          <div>
            <p>
              We leverage data-informed design and iterative design process to deliver platforms and products that are accessible, available and reliable to improve end-users’ experience.
            </p>
          </div>
          <div className='platforms--wrapper'>
          <a href="https://algoboard.com" target="_blank" className='platform--card platform--algoboard--tab tab--algoboard' rel="noreferrer">
              {/* <router-link className="platform--card platform--algoboard--tab tab--algoboard" :to="{ name: 'Products', params: { slug: 'algoboard' }}"> */}
              <div className="flex-equal-inner">
                <div className="flex-equal-content">
                  <div className="platform--header">
                    <svg className="platform--card--icon" viewBox="0 0 2815 2280" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <g filter="url(#filter0_dddd_364_102)">
                        <path d="M1138.32 478.859V478.859C1305.1 401.089 1503.34 473.245 1581.11 640.023L2132.54 1822.56V1822.56C1965.76 1900.33 1767.52 1828.18 1689.75 1661.4L1138.32 478.859Z" fill="white"/>
                        <path d="M1173.04 553.316L1138.32 478.859L1063.86 513.579C1094.47 514.415 1159.15 523.533 1173.04 553.316Z" fill="white"/>
                      </g>
                      <g filter="url(#filter1_dddd_364_102)">
                        <path d="M1463.3 976.376V976.376C1303.94 884.366 1100.16 938.969 1008.15 1098.33L599.724 1805.74V1805.74C759.089 1897.75 962.869 1843.15 1054.88 1683.78L1463.3 976.376Z" fill="white"/>
                        <path d="M1434.46 1026.33L1463.3 976.375L1534.45 1017.45C1505.93 1012.09 1446 1006.35 1434.46 1026.33Z" fill="white"/>
                        <path d="M628.209 1755.71L599.369 1805.66L528.221 1764.59C556.744 1769.95 616.673 1775.69 628.209 1755.71Z" fill="white"/>
                      </g>
                      <path d="M1249.19 463.371V463.371C1415.97 385.601 1614.21 457.757 1691.98 624.535L2243.41 1807.08V1807.08C2076.63 1884.85 1878.39 1812.69 1800.62 1645.91L1249.19 463.371Z" fill="#FC8917"/>
                      <path d="M1283.91 537.828L1249.19 463.371L1174.73 498.091C1205.34 498.927 1270.02 508.045 1283.91 537.828Z" fill="#FC8917"/>
                      <path d="M2317.86 1772.34L2243.42 1807.09L2208.67 1732.64C2232.66 1751.67 2288.08 1786.24 2317.86 1772.34Z" fill="#FC8917"/>
                      <path d="M1409.08 959.947V959.947C1249.71 867.938 1045.93 922.54 953.925 1081.91L545.502 1789.31V1789.31C704.868 1881.32 908.648 1826.72 1000.66 1667.36L1409.08 959.947Z" fill="#FC8917"/>
                      <path d="M1380.24 1009.9L1409.08 959.948L1480.23 1001.02C1451.7 995.658 1391.78 989.919 1380.24 1009.9Z" fill="#FC8917"/>
                      <path d="M573.987 1739.28L545.147 1789.24L474 1748.16C502.522 1753.53 562.451 1759.26 573.987 1739.28Z" fill="#FC8917"/>
                      <defs>
                        <filter id="filter0_dddd_364_102" x="1057.29" y="447.562" width="1081.82" height="1419.45" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_364_102"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="effect1_dropShadow_364_102" result="effect2_dropShadow_364_102"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="effect2_dropShadow_364_102" result="effect3_dropShadow_364_102"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="effect3_dropShadow_364_102" result="effect4_dropShadow_364_102"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_364_102" result="shape"/>
                        </filter>
                        <filter id="filter1_dddd_364_102" x="521.649" y="931.681" width="1019.37" height="931.901" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                          <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_364_102"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="effect1_dropShadow_364_102" result="effect2_dropShadow_364_102"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="effect2_dropShadow_364_102" result="effect3_dropShadow_364_102"/>
                          <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                          <feOffset dy="6.57229"/>
                          <feGaussianBlur stdDeviation="3.28614"/>
                          <feComposite in2="hardAlpha" operator="out"/>
                          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
                          <feBlend mode="normal" in2="effect3_dropShadow_364_102" result="effect4_dropShadow_364_102"/>
                          <feBlend mode="normal" in="SourceGraphic" in2="effect4_dropShadow_364_102" result="shape"/>
                        </filter>
                      </defs>
                    </svg>
                    <div className="platform--title">Algoboard</div>
                  </div>
                  {/* <div className="platform--title">Algoboard</div> */}
                  <div className="platform--description">
                    <p>An EdTech SaaS application that provides a range of educational platforms, giving schools and students the opportunity to learn remotely.</p>
                    <p>Its model aggregates learning resources, thereby reducing the total cost and at the same time making these materials accessible, available and reliable at all time.</p>
                  </div>
                </div>
                <div>
                  <span className="platform--card--more">Learn More &rarr;</span>
                </div>
              </div>
            </a>
            <a href="https://algoforms.com" target="_blank" className="platform--card platform--algoforms--tab tab--algoforms" rel="noreferrer">
              <div className="flex-equal-inner">
                <div className="flex-equal-content">
                  <div className="platform--header">
                    <svg className="platform--card--icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="">
                      <path fill="#374151" d="M11 17a1 1 0 001.447.894l4-2A1 1 0 0017 15V9.236a1 1 0 00-1.447-.894l-4 2a1 1 0 00-.553.894V17zM15.211 6.276a1 1 0 000-1.788l-4.764-2.382a1 1 0 00-.894 0L4.789 4.488a1 1 0 000 1.788l4.764 2.382a1 1 0 00.894 0l4.764-2.382zM4.447 8.342A1 1 0 003 9.236V15a1 1 0 00.553.894l4 2A1 1 0 009 17v-5.764a1 1 0 00-.553-.894l-4-2z"></path>
                    </svg>
                    <div className="platform--title">Algoforms</div>
                  </div>
                  {/* <div className="platform--title">Algoforms</div> */}
                  <div className="platform--description">
                    <p>A low-code (drag and drop) SaaS application for quick surveys and data acquisition.</p>
                    <p>Quality of data is a critical factors in many business decisions, Algoforms helps to drive actionable insights and tell a compelling story that motivates others to engage and act using data.</p>
                  </div>
                </div>
                <div>
                  <span className="platform--card--more">Learn More &rarr;</span>
                </div>
              </div>
            </a>
          </div>
        {/* </div> */}
      </section>
    </div>
  );
};

export default Platform;