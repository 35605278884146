import { gql } from '@apollo/client';

const ADD_SUBSCRIPTION = gql`
  mutation addSubscription($input: addSubscriptionInput!) {
    addSubscription(input: $input) {
      id,
      subscriptionId,
      email,
      created_at,
      updated_at,
      isActive,
    }
  }
`;

export {
  ADD_SUBSCRIPTION
}