
// import { Header } from "../partials";
import { Link } from "react-router-dom";
import './Error.css';

const Error = () => {

  return (
    <div className="error">
      {/* <section className="error--header">
        <Header />
      </section> */}
      <section className="error--main wrapper--container">
        <div className="error--title">
          <h1 className="error--heading">Error Page</h1>
        </div>
        <div className="error--content">
          <p>
            Something happened. Click to go to <Link to="/" className="header--logos px-2.5">home page</Link>
          </p>
        </div>
      </section>
    </div>
  );
};

export default Error;