import { gql } from '@apollo/client';

const ADD_DEMO = gql`
  mutation addDemo($input: addDemoInput!) {
    addDemo(input: $input) {
      id,
      demoId,
      first_name,
      last_name,
      email,
      phone_number,
      created_at,
      platform,
    }
  }
`;

export {
  ADD_DEMO
}